.StudioFiring input {
  width: 6em;
  margin-right: 1em;
}
.StudioFiring span {
  display: inline
}
#buttonGroup {
  width: 410px
}
#buttonGroup button {
  margin-right: 1em;
}
.projectDimensionHeader {
  margin-top: 10px;
}
.inlineInput {
  display: inline-block
}
.fireTempValidationTooltip {
  background-color: rgba(220,53,69,.8)
}