.SubNav {
  width: 100%;
  padding: 4px;
}

.SubNav div {
  display: inline
}

.helloLeft {
  float: left
}

.right {
  float: right;
  margin-right: 8px;
}